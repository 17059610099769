section#Kontakt {

  h2 {
    margin-bottom: 20px;
  }
  p {
    font-family: 'Noto Sans', sans-serif;
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: #fff;
    background: #2e692e;
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 15px;
    font-weight: 700;
    border-radius: 5px;
    text-align: center;
    display: block;
    margin-bottom: 10px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 500;
  }
  .map-container {
    height: 400px;
  }
}
@media screen and (min-width: 402px) {
  section#Kontakt {
    padding-left: calc((100vw - 400px) / 2);
    padding-right: calc((100vw - 400px) / 2);
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    a {
      width: 400px;
      margin: 10px auto;
    }
  }
}
@media screen and (min-width: 1200px) {
  section#Kontakt {
    padding-left: calc((100vw - 1170px) / 2);
    padding-right: calc((100vw - 1170px) / 2);
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
}
