.header {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.2);
  z-index: 5;
  background: white;

  .logo {
    img {
      height: 6.5rem;
      width: auto;
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &[data-active="true"] .mobile-nav-toggle .stroke {
      background: lightgrey;
    }
  }

  .mobile-nav-toggle {
    appearance: none;
    display: flex;
    flex-direction: column;
    width: 30px;
    height: 20px;
    margin-right: 20px;
    padding: 0;
    justify-content: space-between;
    border: 0;
    background: white;
    cursor: pointer;
    outline: 0;

    .stroke {
      width: 100%;
      height: 3px;
      background: black;
      transition: background 0.3s;
    }
  }

  ul {
    flex: 1 0 100%;
    list-style: none;
    align-items: center;
    overflow: hidden;
    padding: 0 20px;
    height: 0;
    transition: height 0.3s ease-out;

    a {
      display: block;
      height: 60px;
      font-size: 2rem;
      line-height: 6rem;
      text-decoration: none;
      color: black;
      text-align: center;
      border-top: 1px solid #ebebeb;
    }
  }
}

@media screen and (min-width: 1200px) {
  .header {
    font-size: 2rem;
    font-weight: 400;

    .mobile-nav-toggle {
      display: none;
    }

    .menu {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: row;
      max-width: 1170px;
      margin: 0 auto;
    }

    ul {
      height: auto !important;
      flex: 0 1 auto;
      display: flex;
      list-style: none;
      align-items: center;
      padding: 0;
      li {
        margin-left: 2rem;
        a {
          text-decoration: none;
          color: $black-three;
          border: 0;
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
}
