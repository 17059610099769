@import '../util.scss';

section.hero-slider {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 70px;
  background: #f7f7f7;
  li {
    height: 32.5rem;
    .text-box {
      width: 100%;
      padding: 20px;
      color: black;
      h3 {
        margin-bottom: 20px;
      }
    }
  }
  .thumb-item {
    outline: 0;
    appearance: none;
    border: 0;
    cursor: pointer;
    width: 30px;
    height: 3px;
    background-color: black;

    &.glide__bullet--active {
      background: $blue;
    }

    img {
      display: none;
    }
  }

  .thumbnail-nav {
    margin-top: 20px;
    padding: 0 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

@media screen and (min-width: 1200px) {
  section.hero-slider {
    padding-left: calc((100vw - 1170px) / 2);
    padding-right: calc((100vw - 1170px) / 2);
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    li {
      height: 550px;
    }
  }
}
